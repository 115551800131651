import Errors from "src/modules/shared/error/errors";
import service from "../expoFullService";
import expoFullListSelectors from "./expoFullListSelector";
import { getHistory } from "src/modules/store";
import Message from "src/view/shared/message";
import CheckDigit from "src/view/export/export-full-booking/new/DigitVerifier";

const prefix = "EXPO_FULL_LIST";

const expoFullListAction = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  RESET_OFFSET: `${prefix}_RESET_OFFSET}`,
  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  CLEAN_SORTER: `${prefix}_CLEAN_SORTER`,
  CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,
  CHANGE_FILTER_LABELS: `${prefix}_CHANGE_FILTER_LABELS`,
  GET_CONTAINER_SUCCESS: `${prefix}_GET_CONTAINER_SUCCESS`,
  GET_CONTAINER_ERROR: `${prefix}_GET_CONTAINER_ERROR`,
  GET_SERVICES: `${prefix}_GET_SERVICES`,
  GET_STATUS: `${prefix}_GET_STATUS`,
  GET_STATUS_OPTIONS: `${prefix}_GET_STATUS_OPTIONS`,

  //FETCH FULL BODY
  FETCH_PREAVISO_STARTED: `${prefix}_FETCH_PREAVISO_STARTED`,
  FETCH_PREAVISO_SUCCESS: `${prefix}_FETCH_PREAVISO_SUCCESS`,
  FETCH_PREAVISO_ERROR: `${prefix}_FETCH_PREAVISO_ERROR`,

  //FETCH FULL BODY
  FETCH_FULL_BODY_STARTED: `${prefix}_FETCH_FULL_BODY_STARTED`,
  FETCH_FULL_BODY_SUCCESS: `${prefix}_FETCH_FULL_BODY_SUCCESS`,
  FETCH_FULL_BODY_ERROR: `${prefix}_FETCH_FULL_BODY_ERROR`,

  //ORDER & PAGINATION FULL BODY
  PAGINATION_CHANGED_FULL_BODY: `${prefix}_PAGINATION_CHANGED_FULL_BODY`,
  SORTER_CHANGED_FULL_BODY: `${prefix}_SORTER_CHANGED_FULL_BODY`,
  CHANGE_FILTER_FULL_BODY: `${prefix}_CHANGE_FILTER_FULL_BODY`,
  RESET_OFFSET_FULL_BODY: `${prefix}_RESET_OFFSET_FULL_BODY`,

  //FETCH FULL HEADER ID
  FETCH_FULL_HEADER_ID_STARTED: `${prefix}_FETCH_FULL_HEADER_ID_STARTED`,
  FETCH_FULL_HEADER_ID_SUCCESS: `${prefix}_FETCH_FULL_HEADER_ID_SUCCESS`,
  FETCH_FULL_HEADER_ID_ERROR: `${prefix}_FETCH_FULL_HEADER_ID_ERROR`,

  //ENVIAR PREAVISO
  PREAVISO_STARTED: `${prefix}_PREAVISO_STARTED`,
  PREAVISO_SUCCESS: `${prefix}_PREAVISO_SUCCESS`,
  PREAVISO_ERROR: `${prefix}_PREAVISO_ERROR`,

  doFetch: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: expoFullListAction.FETCH_STARTED
      });
      const response = await service.getAllFullHeader(
        expoFullListSelectors.selectOrderBy(getState()),
        expoFullListSelectors.selectLimit(getState()),
        expoFullListSelectors.selectOffset(getState()),
        expoFullListSelectors.selectFilter(getState())
      );

      dispatch({
        type: expoFullListAction.FETCH_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);

      dispatch({
        type: expoFullListAction.FETCH_ERROR,
      });
    }
  },

  doFetchPreaviso: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: expoFullListAction.FETCH_PREAVISO_STARTED
      });
      const response = await service.getPreaviso(id);

      dispatch({
        type: expoFullListAction.FETCH_PREAVISO_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.showMessage(error);

      dispatch({
        type: expoFullListAction.FETCH_PREAVISO_ERROR,
      });
    }
  },

  fullHeaderStatus: () => async (dispatch) => {
    try {
      const response = await service.fullHeaderStatus();
      dispatch({
        type: expoFullListAction.GET_STATUS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  optionsFullHeaderStatus: () => async (dispatch) => {
    try {
      const response = await service.fullHeaderStatus();
      dispatch({
        type: expoFullListAction.GET_STATUS_OPTIONS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: expoFullListAction.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(expoFullListAction.doFetch());
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: expoFullListAction.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(expoFullListAction.doFetch());
  },

  cleanSorter: () => async (dispatch) => {
    dispatch({
      type: expoFullListAction.CLEAN_SORTER,
    });

    dispatch(expoFullListAction.doFetch());
  },

  doChangeFilter: (values) => async (dispatch) => {
    dispatch({
      type: expoFullListAction.RESET_OFFSET,
    });
    dispatch({
      type: expoFullListAction.CHANGE_FILTER,
      payload: values,
    });

    dispatch(expoFullListAction.doFetch());
  },

  doChangeFilterLabel: (values) => async (dispatch) => {

    dispatch({
      type: expoFullListAction.CHANGE_FILTER_LABELS,
      payload: values,
    });

  },

  

  // FETCH FULL HEADER ID
  findFullHeaderById: (data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: expoFullListAction.FETCH_FULL_HEADER_ID_STARTED,
      });

      const response = await service.findFullHeaderById(
        expoFullListSelectors.selectOrderBy(getState()),
        expoFullListSelectors.selectLimit(getState()),
        expoFullListSelectors.selectOffset(getState()),
        expoFullListSelectors.selectFilter(getState()),
        data
      );
      console.log(response)
      dispatch({
        type: expoFullListAction.FETCH_FULL_HEADER_ID_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: expoFullListAction.FETCH_FULL_HEADER_ID_SUCCESS,
      });
    }
  },

  // FETCH FULL BODY BY HEADER ID
  findFullBodyByFullHeaderId: (data) => async (dispatch, getState) => {
    try {
      
      dispatch({
        type: expoFullListAction.FETCH_FULL_BODY_STARTED,
      });

      const response = await service.findFullBodyByFullHeaderId(
        data,
        expoFullListSelectors.selectOrderByFullBody(getState()),
        expoFullListSelectors.selectLimitFullBody(getState()),
        expoFullListSelectors.selectOffsetFullBody(getState()),
        expoFullListSelectors.selectFilterFullBody(getState())
      );
      
      dispatch({
        type: expoFullListAction.FETCH_FULL_BODY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: expoFullListAction.FETCH_FULL_BODY_ERROR,
      });
    }
  },

  //ORDER & PAGINATION FULL BODY 
  doChangePaginationFullBody: (pagination, data?) => async (dispatch) => {
    dispatch({
      type: expoFullListAction.PAGINATION_CHANGED_FULL_BODY,
      payload: pagination,
    });

    dispatch(expoFullListAction.findFullBodyByFullHeaderId(data));
  },

  doChangeSortFullBody: (sorter, data?) => async (dispatch) => {
    dispatch({
      type: expoFullListAction.SORTER_CHANGED_FULL_BODY,
      payload: sorter,
    });

    dispatch(expoFullListAction.findFullBodyByFullHeaderId(data));
  },

  doChangeFilterFullBody: (values, data?) => async (dispatch) => {
    dispatch({
      type: expoFullListAction.RESET_OFFSET_FULL_BODY,
    });
    dispatch({
      type: expoFullListAction.CHANGE_FILTER_FULL_BODY,
      payload: values,
    });

    dispatch(expoFullListAction.findFullBodyByFullHeaderId(data));
  },
  
  searchContainer:
    (data, id,setContainer?, setRequired?) => async (dispatch, getState) => {
      try {
        const response = await service.searchContainer(data,id);
        const {
          data: { type_iso, tare_weight },
        } = response;
        dispatch({
          type: expoFullListAction.GET_CONTAINER_SUCCESS,
          payload: response,
        });
        setContainer(type_iso, tare_weight);
        setRequired(false);
        Message.success(`${response.message}`);
      } catch (error) {
        
        if (CheckDigit.validationCreateCheckDigit(`${data}`) === true) {
          Errors.handleErrorContainer(error,setRequired)
        } else {
          Errors.handleErrorContainerUse(error,setRequired)
        }
        dispatch({
          type: expoFullListAction.GET_CONTAINER_ERROR,
        });
      }
    },
    
    resetSearchContainer: () => async (dispatch, getState) => {
      dispatch({
          type: expoFullListAction.GET_CONTAINER_ERROR,
        })
    },
  
  // SERVICES
  getServicies: (data?) => async (dispatch, getState) => {
    try {
      const response = await service.getServicies(data);

      dispatch({
        type: expoFullListAction.GET_SERVICES,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },
  scheduledOther: (data?) => async (dispatch, getState) => {
    try {
      const response = await service.scheduledOtherFull(data);
      getHistory().push(`/turns/exportfull/request/${data.idHeader}`);
      Message.success(`${response.message}`);
    } catch (error) {
      Errors.showMessage(error);
    }
  },

};

export default expoFullListAction;
