import { createSelector } from 'reselect';

const selectRaw = (state) => state.myusers.list;

const selectRows = createSelector([selectRaw], (raw) => raw.rows);
const selectMessage = createSelector([selectRaw], (raw) => raw.message);
const selectCode = createSelector([selectRaw], (raw) => raw.code);
const selectData = createSelector([selectRaw], (raw) => raw.data);
const selectLoading = createSelector([selectRaw], (raw) => raw.loading);
const selectCount = createSelector([selectRaw], (raw) => raw.count);
const selectHasRows = createSelector([selectRaw], (raw) => raw.count > 0);
const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});
const selectOrderBy = createSelector([selectRaw], (raw) => {
	const sorter = raw.sorter;

	if (!sorter) {
		return null;
	}

	if (!sorter.field) {
		return null;
	}

	let direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

	return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
	return raw.filter;
});

const selectRawFilter = createSelector([selectRaw], (raw) => {
	return raw.rawFilter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;
	return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
	const pagination = raw.pagination;

	if (!pagination || !pagination.pageSize) {
		return 0;
	}

	const current = pagination.current || 1;

	return current - 1;
});

const selectPagination = createSelector([selectRaw, selectCount], (raw, count) => {
	return {
		...raw.pagination,
		total: count,
	};
});

const selectRoles = createSelector([selectRaw], (raw) => raw.roles);

const usersListSelectors = {
	selectLimit,
	selectOffset,
	selectMessage,
	selectCode,
	selectData,
	selectRows,
	selectLoading,
	selectCount,
	selectHasRows,
	selectSorter,
	selectOrderBy,
	selectFilter,
	selectPagination,
	selectRoles,
	selectRawFilter,


};

export default usersListSelectors;
