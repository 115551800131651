import actions from './usersListActions';

const INITIAL_PAGE_SIZE = 10;

const initialState = {
	rows: [],
	message: '',
	code: '',
	loading: false,
	pagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	orderPagination: {
		current: 1,
		pageSize: INITIAL_PAGE_SIZE,
	},
	sorter: {},
	count: 0,
	orderCount: 0,
	filter: {},
	orderFilter: {},
	roles:[]

};

const usersListReducer = (state = initialState, { type, payload }) => {
	
	if (type === actions.RESET_OFFSET) {
		return {
			...state,
			pagination: {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}
	if (type === actions.FETCH_STARTED) {
		return {
			...state,
			rows: null,
			loading: true,
			count: 0
		};
	}
	if (type === actions.SWITCH_STARTED) {
		return {
			...state,
			loading: true,
		};
	}


	if (type === actions.FETCH_SUCCESS) {
		
		return {
			...state,
			rows: payload.list,
			count: payload.total,
			loading: false,
		};
	}
	if (type === actions.UPDATE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}
	
	if (type === actions.PAGINATION_CHANGED) {
		return {
			...state,
			pagination: payload || {
				current: 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		};
	}

	if (type === actions.SORTER_CHANGED) {
		return {
			...state,
			sorter: payload || {},
		};
	}

	if (type === actions.CHANGE_FILTER) {
		return {
			...state,
			filter: payload,
		};
	}
	
	if(type === actions.START_RESET){
		return {
			...state,
			code : "",
			message: ""
		}
	}
	
	if (type === actions.FETCH_ERROR) {
		return {
			...state,
			rows: null,
			loading: false,
			

			
		};
	}
	if (type === actions.NEW_USER_STARTED) {
		return {
			...state,
			user: null,
			message: '',
			code: '',
			loading: true,
		};
	}

	if (type === actions.NEW_USER_SUCCESS) {
		return {
			...state,
			user: payload,
			message: payload.message,
			code: payload.code,
			loading: false,

			
		};
	}

	if (type === actions.NEW_USER_ERROR) {
		return {
			...state,
			user: null,
			message: payload?.message,
			code: payload?.code,
			loading: false,

			
		};
	}
	if (type === actions.FETCH_ROLES_SUCCESS) {
		return {
			...state,
			roles: payload,
		};
	}

	
	return state;
};

export default usersListReducer;
