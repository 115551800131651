import authAxios from '../../shared/axios/authAxios';
import authAxios2 from '../../shared/axios/authAxios2';
import { TokenAzureAd } from '../../auth/TokenAzureAd';

const url = {
	ClientCompanyUser: "client-company",
	StakeholderUser: "stakeholder",
	SuperUser: "super-user"
}

export default class usersService {
	static async getAllUsers(orderBy?, limit?, offset?, filter?) {
		const params = {
			sort: orderBy,
			size: limit,
			page: offset,
			filter: filter,
		};

		if (params.filter && params.filter.email) {
			params.filter.email = params.filter.email.trim();
		}
		console.log(params)
		const response = await authAxios2.get('/portal/user', { params });
		return response.data;
	}

	static async addUser(data, userType) {
		const token = await TokenAzureAd.getAccessToken();
		const body = { ...data, token };
		const response = await authAxios2.post(`/portal/user/${url[userType]}`, body);

		return response.data;
	}

	static async desactivateUser(id) {
		const token = await TokenAzureAd.getAccessToken();
		const params = { token }
		const response = await authAxios2.delete(`/portal/user/${id}`, { params });
		return response.data; //usuario desactivado
	}

	static async activateUser(id) {
		const token = await TokenAzureAd.getAccessToken();
		const params = { token }
		const response = await authAxios2.patch(`/portal/user/${id}/enabled`, null, { params });
		return response.data;
	}

	static async editUser(email, data) {
		const response = await authAxios2.put(`/portal/user/${email}`, data);
		return response.data;
	}

	static async getRelation(id) {
		const response = await authAxios.get(`/user/${id}`);
		return response.data;
	}

	static async getRoles(boolean) {
		const response = await authAxios.get(`/portal/user/roles?hideAdmin=${boolean}`);

		return response.data;
	}

}
