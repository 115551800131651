import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import Service from '../usersService';
import selectors from './usersListSelectors';

const prefix = 'USERS_LIST';

const usersListActions = {
	FETCH_STARTED: `${prefix}_FETCH_STARTED`,
	FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
	FETCH_ERROR: `${prefix}_FETCH_ERROR`,

	NEW_USER_STARTED: `${prefix}_NEW_USER_STARTED`,
	NEW_USER_SUCCESS: `${prefix}_NEW_USER_SUCCESS`,
	NEW_USER_ERROR: `${prefix}_NEW_USER_ERROR`,
	
	START_RESET: `${prefix}_START_RESET`,

	PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

	SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

	CHANGE_FILTER: `${prefix}_CHANGE_FILTER`,

	FETCH_ROLES: `${prefix}_FETCH_ROLES`,

	SWITCH_STARTED: `${prefix}_SWITCH_STARTED`,

	FETCH_USER_TYPES: `${prefix}_FETCH_USER_TYPES`,

	FETCH_ROLES_STARTED: `${prefix}_FETCH_ROLES_STARTED`,
	FETCH_ROLES_SUCCESS: `${prefix}_FETCH_ROLES_SUCCESS`,
	FETCH_ROLES_ERROR: `${prefix}_FETCH_ROLES_ERROR`,

	UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,
	RESET_OFFSET: `${prefix}_RESET_OFFSET`,


	doFetch: () => async (dispatch, getState) => {
		try {
			dispatch({
				type: usersListActions.FETCH_STARTED,
			});

			const response = await Service.getAllUsers(
				selectors.selectOrderBy(getState()),
				selectors.selectLimit(getState()),
				selectors.selectOffset(getState()),
				selectors.selectFilter(getState()),
			);
			dispatch({
				type: usersListActions.FETCH_SUCCESS,
				payload: response,
			});

		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: usersListActions.FETCH_ERROR,
			});
		}
	},
	updateFilter: (filter) => async (dispatch, getState) => {
		console.log(filter)
		dispatch({
			type: usersListActions.RESET_OFFSET,
		});

		dispatch({
			type: usersListActions.UPDATE_FILTER,
			payload: filter,
		});

		dispatch(usersListActions.doFetch());
	},
	doResetCode: () => async (dispatch, getState) => {
		dispatch({
			type: usersListActions.START_RESET,
		});
	},

	doCreateUser: (data) => async (dispatch, getState) => {
		try {
			dispatch({
				type: usersListActions.NEW_USER_STARTED,
			});
			const userType = authSelectors.selectCurrentUserUserType(getState());
			const response = await Service.addUser(data, userType);
			dispatch({
				type: usersListActions.NEW_USER_SUCCESS,
				payload: response,
			});
			response.code === 'MS-CO-OP004-ERR-001' && Message.error('El usuario ya existe.')
			response.code === 'MS-CO-OP004-ERR-002' && Message.error('No puede crear un usuario de tipo ADMIN.')
			response.code === 'MC-CO-OK-001' && Message.success(i18n('response.userCreated'));
			response.code === 'MC-CO-OK-001' && dispatch(usersListActions.doFetch());

		} catch (error) {
			Errors.handle(error);

			dispatch({
				type: usersListActions.NEW_USER_ERROR,
			});
		}
	},

	doEdit: (email, data) => async (dispatch) => {
		try {
			await Service.editUser(email, data);
			dispatch(usersListActions.doFetch());
			Message.success(i18n('Usuario editado correctamente'));
		} catch (error) {
			Errors.handle(error);
		}
	},

	doChangePagination: (pagination) => async (dispatch) => {
		dispatch({
			type: usersListActions.PAGINATION_CHANGED,
			payload: pagination,
		});

		dispatch(usersListActions.doFetch());
	},

	doChangeSort: (sorter) => async (dispatch) => {
		dispatch({
			type: usersListActions.SORTER_CHANGED,
			payload: sorter,
		});

		dispatch(usersListActions.doFetch());
	},

	doChangeFilter: (values) => async (dispatch) => {
		dispatch({
			type: usersListActions.CHANGE_FILTER,
			payload: values,
		});

		dispatch(usersListActions.doFetch());
	},

	switchUser: (row: any, funcionLoading: Function) => async (dispatch) => {
		const {
		  id, 
		  enabled, 
		  firstName,
		  lastName
		} = row;
		dispatch({
			type: usersListActions.SWITCH_STARTED,

		})
		enabled && await Service.desactivateUser(id);
		enabled || await Service.activateUser(id);
	
		funcionLoading((backValue: Boolean) => !backValue);

		dispatch(usersListActions.doFetch());
		Message.success(`El usuario ${firstName} ${lastName} se ha ${enabled? "desactivado":"activado"} correctamente`); 
	  },

	  getRoles: (boolean) => async (dispatch, getState) => {
		dispatch({
			type: usersListActions.FETCH_ROLES_STARTED,
		});
		try {
			const response = await Service.getRoles(boolean)
			dispatch({
				type: usersListActions.FETCH_ROLES_SUCCESS,
				payload: response,
			});
		}
		catch{
			dispatch({
				type: usersListActions.FETCH_ROLES_ERROR,
			})
		}
	},
	
};

export default usersListActions;
